import axios, {AxiosInstance} from 'axios';
import {Cookies, Notify, Platform} from 'quasar';
import {useMainStore} from 'stores/main';
// import * as Sentry from '@sentry/vue';
import {reloadApp} from 'src/etc/helper';
import {CapacitorPreferences} from 'boot/inject-capacitor';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

let api

export default (({app, ssrContext, store}) => {
  const mainStore = useMainStore(store)
  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies // otherwise we're on client
  const platform = process.env.SERVER
    ? Platform.parseSSR(ssrContext)
    : Platform // otherwise we're on client


  async function setOrCreateDeviceId() {
    let deviceId
    let isDeviceId = false

    if (platform.is.nativeMobile) {
      const {value} = await CapacitorPreferences.get({key: 'deviceId'})
      if (value && value.length > 0 && value !== '') {
        isDeviceId = true
      }
      deviceId = value
    } else {
      isDeviceId = cookies.has('deviceId')
      deviceId = cookies.get('deviceId')
    }

    if (isDeviceId) {
      mainStore.deviceId = deviceId
    } else {
      if (crypto && crypto.randomUUID) {
        deviceId = crypto.randomUUID(); // Generate UUID
      } else {
        deviceId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      }

      if (platform.is.nativeMobile) {
        await CapacitorPreferences.set({key: 'deviceId', value: deviceId})
      } else {
        cookies.set('deviceId', deviceId)
      }
    }
    mainStore.deviceId = deviceId

    return deviceId
  }


  // Be careful when using SSR for cross-request state pollution
  // due to creating a Singleton instance here;
  // If any client changes this (global) instance, it might be a
  // good idea to move this instance creation inside of the
  // "export default () => {}" function below (which runs individually
  // for each client)
  api = axios.create({
    baseURL: process.env.API_BASE,
    responseType: 'json',
    paramsSerializer: {
      indexes: null // array indexes format (null - no brackets, false - empty brackets, true - brackets with indexes)
    },
    headers: {
      'Accept-Language': 'de-de'
    },
  });

  api.interceptors.response.use(
    (resp) => {
      if (resp.headers['x-flag'] && mainStore.testFlag !== resp.headers['x-flag']) {
        mainStore.testFlag = resp.headers['x-flag']
      }
      return resp
    },
    (err) => {
      if (mainStore.isErrorPage) {
        return Promise.reject(err)
      }

      const {message, response} = err

      // set network error in store
      mainStore.serverNetworkError = message === 'Network Error' || response?.status >= 500;

      // Handle unauthorized error
      if (response?.status === 401) {
        if (response.data?.code === 'authentication_failed') {
          if (cookies.has('token')) {
            console.error('Removed cookie "token": 401', response)

            cookies.remove('token', {path: '/'})
            console.debug('logout: removed token')

          }
          if (platform.is.nativeMobile) {
            CapacitorPreferences.remove({key: 'token'}).then(() => {
              console.debug('logout: removed token from preferences')
            })
          }


          if (!process.env.SERVER) {
            reloadApp().then(() => {
              console.debug('reloading app')
            })
          }
        }
        return Promise.reject(err);
      }

      // Handle rate limit error
      if (response?.status === 429) {
        Notify.create({
          type: 'negative',
          message: response.data.detail[0],
          caption: response.data.detail.length > 1 ? response.data.detail[1] : undefined
        });
        return Promise.reject(err);
      }

      // Handle no subscription
      if (response?.status === 402) {
        // Notify.create({
        //   color: 'warning',
        //   message: response.data.detail,
        //   actions: [{icon: 'close', 'aria-label': 'Schließen', round: true, dense: true, color: 'white'}]
        // });
        // return Promise.reject(err);
        return Promise.reject(err)
      }

      // // Handle client errors except 404
      if (
        response?.status !== 404
        && response?.status >= 400
        && response?.status < 500
        && response.data?.detail
        && response.data?.detail !== 'not active'
      ) {
        Notify.create({
          type: 'negative',
          message: response.data.detail || response.data.non_field_errors?.join(' ') || message,
          timeout: 0,
          actions: [{icon: 'close', 'aria-label': 'Schließen', round: true, dense: true, color: 'white'}]
        });
        if (response.data?.code) {
          console.error('Error code', response.data.code)
        }

        // Sentry.captureException(err, {
        //   extra: {responseDetail: response.data.detail},
        //   tags: {
        //     axiosError: true
        //   }
        // })

        return Promise.reject(err);
      }


      // Handle network or server errors
      if (message === 'Network Error' || response?.status >= 500) {
        const excludedUrls: string[] = ['chats/unread/', 'blog/json/'];

        if (excludedUrls.some(url => err.config.url.includes(url))) {
          return Promise.reject(err);
        }

        // Sentry.captureException(err, {
        //   extra: {responseDetail: 'Network Error'},
        //   tags: {
        //     axiosError: true
        //   }
        // })

        Notify.create({
          type: 'negative',
          message: message === 'Network Error' ? 'Keine Verbindung zum Server' : 'Es ist ein Fehler aufgetreten',
          caption: 'Bitte versuche es später noch einmal.',
          actions: [{icon: 'close', 'aria-label': 'Schließen', round: true, dense: true, color: 'white'}]
        });
      }

      return Promise.reject(err)
    }
  )

  api.interceptors.request.use(async (config) => {
    if (platform.is.capacitor) {
      const {value} = await CapacitorPreferences.get({key: 'token'})
      if (value) {
        config.headers.Authorization = `Token ${value}`
      }
    } else {
      const token = cookies.get('token')
      if (token) {
        config.headers.Authorization = `Token ${token}`
      }
    }

    if (!mainStore.deviceId) {
      await setOrCreateDeviceId()
    }

    if (mainStore.deviceId) {
      config.headers['X-UID'] = mainStore.deviceId
    }
    return config
  })
  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export {api};
