import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "q-mb-md"
}
const _hoisted_2 = {
  key: 1,
  class: "q-mb-md"
}
const _hoisted_3 = { class: "text-h4" }
const _hoisted_4 = ["innerHTML"]

import {useDialogPluginComponent} from 'quasar'
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDialog',
  props: {
  title: String,
  message: String,
  imageUrl: String,
  buttonLabel: {type: String, default: 'Ok'},
},
  emits: [
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
],
  setup(__props) {





const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()
// dialogRef      - Vue ref to be applied to QDialog
// onDialogHide   - Function to be used as handler for @hide on QDialog
// onDialogOK     - Function to call to settle dialog with "ok" outcome
//                    example: onDialogOK() - no payload
//                    example: onDialogOK({ /*...*/ }) - with payload
// onDialogCancel - Function to call to settle dialog with "cancel" outcome

// this is part of our example (so not required)
function onOKClick() {
  // on OK, it is REQUIRED to
  // call onDialogOK (with optional payload)
  onDialogOK()
  // or with payload: onDialogOK({ ... })
  // ...and it will also hide the dialog automatically
}

return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    onHide: _unref(onDialogHide)
  }, {
    default: _withCtx(() => [
      _createVNode(BaseDialogContent, { "hide-header-separator": "" }, {
        default: _withCtx(() => [
          (__props.imageUrl)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_q_img, {
                  class: "rounded-borders full-width",
                  alt: "Image",
                  src: __props.imageUrl
                }, null, 8, ["src"])
              ]))
            : _createCommentVNode("", true),
          (__props.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h4", _hoisted_3, _toDisplayString(__props.title), 1)
              ]))
            : _createCommentVNode("", true),
          (__props.message)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                innerHTML: __props.message
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                color: "primary",
                flat: "",
                label: __props.buttonLabel,
                onClick: onOKClick
              }, null, 8, ["label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}
}

})