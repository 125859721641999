import {ActionPerformed, PushNotificationSchema} from '@capacitor/push-notifications';
import {handlers} from 'src/apps/push-notifications/handlers';
import {BasePayload} from 'src/apps/push-notifications/push-notification.models';
import {Loading} from 'quasar';
import {CapacitorPushNotifications} from 'boot/inject-capacitor';


export async function removeNotifications(chatUuid) {
  if (!CapacitorPushNotifications) return
  if (!chatUuid) return;
  const chatTag = 'chat_' + chatUuid;

  try {
    const response = await CapacitorPushNotifications.getDeliveredNotifications();
    // Ensure we always have an array, even if notifications is null or undefined
    const notifications = response.notifications || [];
    if (!notifications?.length) return

    console.debug('removeNotifications', chatUuid, chatTag)
    const filtered = notifications.filter(notification => notification.tag === chatTag);

    if (filtered.length > 0) {
      await CapacitorPushNotifications.removeDeliveredNotifications({notifications: filtered});
    }
  } catch (err) {
    console.error('Error removing notifications:', err);
  }
}

/**
 * This function is *only* called when a push notification is received when the user is in the app.
 *
 * @param notification
 * @param extras
 */
export async function pushNotificationReceived(
  notification: PushNotificationSchema,
  {...extras}
) {
  console.debug('pushNotificationReceived', JSON.stringify(notification))
  const data = notification.data;

  if (data.type === 'new_message' && extras.mainStore.isLoggedIn) {
    await extras.mainStore.fetchUnreadMessagesCount()

    if (!window) return
    let cleanedPath = window.location.href
    if (cleanedPath.includes('/#')) {
      // Split at '/#' and prepend a slash to get the route path ("/messenger/")
      const parts = cleanedPath.split('/#');
      cleanedPath = '' + parts[1];
    }

    const resolved = extras.router.resolve(cleanedPath)

    if (resolved.name !== 'messenger-chat' || !resolved.params?.chatUuid) {
      return
    }

    // remove existing notifications for this chat
    await removeNotifications(resolved.params.chatUuid)
  }
}


/**
 * This function is called when a push notification is clicked by the user.
 *
 */
export async function pushNotificationActionPerformed(
  notification: ActionPerformed,
  {...extra}
) {
  console.debug('pushNotificationActionPerformed', JSON.stringify(notification))
  const data: BasePayload = notification.notification.data

  // set default handler if no handler is found
  const messageType = data.type && Object.keys(handlers).includes(data.type) ? data.type : 'default'
  console.debug('Handling notification with type', messageType)

  // run handler
  Loading.show()
  try {
    const HandlerCls = handlers[messageType]
    await HandlerCls.create(notification, extra)
  } catch (e) {
    console.error('pushNotificationActionPerformed', e)
  } finally {
    Loading.hide()
  }
}
