import {Cookies} from 'quasar';
import {api} from 'boot/axios';
import {useMainStore} from 'stores/main';
import {useSubscriptionStore} from 'src/apps/subscription/store';
import {updateAppNotificationPermission} from 'src/etc/app-utils';
import {pushNotificationActionPerformed, pushNotificationReceived} from 'src/apps/push-notifications/listeners';


let
  CapacitorGeolocation,
  CapacitorPreferences,
  CapacitorPushNotifications,
  CapacitorShare,
  CapacitorCore,
  CapacitorDevice,
  CapacitorCamera,
  CapacitorFilesystem,
  CapacitorDirectory,
  CapacitorBrowser,
  CapacitorApp,
  CapacitorLocalNotifications,
  CapacitorNetwork,
  CapacitorTrackingTransparency,
  CapacitorLiveUpdate,
  CapacitorInAppReview,
  CapacitorNativeSettings,
  CapacitorAppUpdate,
  CapacitorBadge


export default (async ({ssrContext, router, store, urlPath}) => {
  const mainStore = useMainStore(store)
  const subStore = useSubscriptionStore(store)

  const {Capacitor} = await import('@capacitor/core')
  const {LocalNotifications} = await import('@capacitor/local-notifications')
  const {Device} = await import('@capacitor/device')
  const {Camera} = await import('@capacitor/camera')
  const {Geolocation} = await import('@capacitor/geolocation')
  const {Preferences} = await import('@capacitor/preferences')
  const {PushNotifications} = await import('@capacitor/push-notifications')
  const {Share} = await import('@capacitor/share')
  const {Browser} = await import('@capacitor/browser')
  const {App} = await import('@capacitor/app')
  const {Filesystem, Directory} = await import('@capacitor/filesystem')
  const {Network} = await import('@capacitor/network')
  const {AppTrackingTransparency} = await import('capacitor-ios-app-tracking')
  const {LiveUpdate} = await import('@capawesome/capacitor-live-update')
  const {InAppReview} = await import('@capacitor-community/in-app-review')
  const {NativeSettings} = await import('capacitor-native-settings')
  const {AppUpdate} = await import('@capawesome/capacitor-app-update')
  const {Badge} = await import('@capawesome/capacitor-badge')

  CapacitorGeolocation = Geolocation
  CapacitorPreferences = Preferences
  CapacitorPushNotifications = PushNotifications
  CapacitorShare = Share
  CapacitorCore = Capacitor
  CapacitorDevice = Device
  CapacitorCamera = Camera
  CapacitorFilesystem = Filesystem
  CapacitorDirectory = Directory
  CapacitorBrowser = Browser
  CapacitorApp = App
  CapacitorLocalNotifications = LocalNotifications
  CapacitorNetwork = Network
  CapacitorTrackingTransparency = AppTrackingTransparency
  CapacitorLiveUpdate = LiveUpdate
  CapacitorInAppReview = InAppReview
  CapacitorNativeSettings = NativeSettings
  CapacitorAppUpdate = AppUpdate
  CapacitorBadge = Badge

  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies // otherwise we're on client

  // set token if in Preferences
  try {
    const {value} = await Preferences.get({key: 'token'})
    // check if value is empty string or set
    if (value) {
      cookies.set('token', value || '', {
        path: '/',
        secure: !process.env.DEV,
        sameSite: 'Strict',
      })
    }
  } catch (err) {
    console.error(err)
  }

  const addListeners = async () => {
    await LocalNotifications.addListener('localNotificationActionPerformed', async (/*notification*/) => {
      console.debug('localNotificationActionPerformed event')
    })

    await PushNotifications.addListener('registration', async (token) => {
      console.debug('Registration token: ', token.value);
      const info = await Device.getInfo();
      const id = await Device.getId();
      const data = {
        name: info.name,
        registration_id: token.value.toString(),
        device_id: id.identifier ?? mainStore.deviceId,
        active: true,
        type: Capacitor.getPlatform(),
        permission_status: 'granted'
      }
      await api.post('devices/', data).then(() => {
        console.debug('Notifications granted')
      }).catch((err) => {
        console.error('Error:', err.message)
      })
    })

    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', async notification => {
      await pushNotificationReceived(notification, {router, mainStore})
    })

    await PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      await pushNotificationActionPerformed(notification, {router, urlPath, mainStore})
    })

    await App.addListener('resume', function () {
      if (mainStore.isLoggedIn) {
        mainStore.fetchUnreadMessagesCount()
        mainStore.fetchAccountDetails()
        subStore.fetchAccountQuota()
        subStore.fetchAccountSubscription()
      }

      updateAppNotificationPermission()
      console.debug('App resumed')
    })
  }

  try {
    await addListeners()
  } catch (e) {
    console.warn('could not run push notification listeners\n', e)
  }
})

export {
  CapacitorPreferences,
  CapacitorGeolocation,
  CapacitorPushNotifications,
  CapacitorShare,
  CapacitorCore,
  CapacitorDevice,
  CapacitorCamera,
  CapacitorFilesystem,
  CapacitorDirectory,
  CapacitorBrowser,
  CapacitorApp,
  CapacitorLocalNotifications,
  CapacitorNetwork,
  CapacitorTrackingTransparency,
  CapacitorLiveUpdate,
  CapacitorInAppReview,
  CapacitorNativeSettings,
  CapacitorAppUpdate,
  CapacitorBadge
}
