import {RouteRecordRaw} from 'vue-router';
import Auth from 'src/router/routes/auth';
import Subscription from 'src/apps/subscription/routes';
import Messenger from 'src/apps/messenger/routes';
import Search from 'src/apps/search/routes';
import Tools from 'src/router/routes/tools';
import Business from 'src/router/routes/business';
import Widgets from 'src/router/routes/widgets';
import Payment from 'src/router/routes/payment';
import Advert from 'src/router/routes/advert';
import Market from 'src/router/routes/market';
import Stories from 'src/apps/stories/routes';

let routes: RouteRecordRaw[] = []

routes = [
  {
    path: '',
    component: () => import('src/apps/search/layouts/SearchWrapperLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('layouts/AppMainLayout.vue'),
        children: [
          {
            path: '',
            component: () => import('src/apps/search/pages/SearchPage.vue'),
            name: 'search',
            meta: {noHeader: true}
          },
        ]
      },
    ],
  },

  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    children: [
      {
        path: '/favoriten',
        alias: '/favorites',
        component: () => import('src/pages/FavoritesPage.vue'),
        name: 'favorites',
        meta: {loginRequired: true}
      },

      {
        path: '/login',
        component: () => import('src/pages/auth/LoginPage.vue'),
        name: 'login',
      },
    ],
  },

  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/ueber-uns',
        alias: '/about-us',
        component: () => import('pages/static/AboutUsPage.vue'),
        name: 'about-us',
      },
      {
        path: '/partner',
        component: () => import('pages/static/PartnerPage.vue'),
        name: 'partner',
      },
      {
        path: '/faq-hilfe',
        alias: '/faq-help',
        component: () => import('pages/static/FaqAndHelpPage.vue'),
        name: 'faq-help',
      },
      {
        path: '/datenschutz',
        alias: '/privacy',
        component: () => import('pages/static/PrivacyPage.vue'),
        name: 'privacy',
      },
      {
        path: '/impressum',
        alias: '/imprint',
        component: () => import('pages/static/ImprintPage.vue'),
        name: 'imprint',
      },
      {
        path: '/kontakt',
        alias: '/contact',
        component: () => import('pages/static/ContactPage.vue'),
        name: 'contact',
      },
    ]
  },

  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
    name: 'not-found',
  },

  {
    path: '/error-404',
    component: () => import('pages/ErrorNotFound.vue'),
    name: '404',
  },
]

routes = routes.concat(Auth)
routes = routes.concat(Advert)
routes = routes.concat(Subscription)
routes = routes.concat(Tools)
routes = routes.concat(Business)
routes = routes.concat(Widgets)
routes = routes.concat(Payment)
routes = routes.concat(Messenger)
routes = routes.concat(Search)
routes = routes.concat(Market)
routes = routes.concat(Stories)

routes = routes.concat([
  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/redirect',
        component: () => import('pages/ExternalLinkRedirect.vue'),
        name: 'redirect',
      },
    ]
  },
  {
    path: '/download-app',
    component: () => import('src/apps/core/pages/AppDownloadRedirect.vue'),
    name: 'download-app'
  },
])


if (process.env.DEV) {
  routes.push({
      path: '',
      component: () => import('layouts/BackBtnLayout.vue'),
      children: [
        {
          path: '/dev',
          name: 'developer',
          component: () => import('src/pages/DevPage.vue')
        }
      ],
    }
  )
}
export default routes
