import {ActionPerformed} from '@capacitor/push-notifications';
import {CapacitorBrowser} from 'boot/inject-capacitor';
import {Router} from 'vue-router';
import {
  BasePayload,
  InAppPayload,
  LinkPayload,
  NewMessagePayload,
  SearchAgentPayload
} from 'src/apps/push-notifications/push-notification.models';
import {useTracking} from 'src/etc/tracking';
import InAppDialog from 'src/apps/push-notifications/components/InAppDialog.vue';
import {Dialog} from 'quasar';


interface ActionPerformedHandlerInterface {
  handle(): Promise<void>
}


interface ActionHandlerExtras {
  router?: Router
  mainStore?: any
}


abstract class ActionPerformedHandler implements ActionPerformedHandlerInterface {
  notification: ActionPerformed
  extras: ActionHandlerExtras = {}
  router: Router
  mainStore: any

  constructor(notification: ActionPerformed, {...extras}) {
    this.notification = notification
    this.extras = extras
    this.router = extras.router
    this.mainStore = extras.mainStore

    this.trackAction(notification)
    console.debug(this.constructor.name)
  }

  // Use a static async factory method to handle asynchronous initialization
  static async create<T extends ActionPerformedHandler>(
    this: new (notification: ActionPerformed, extra: ActionHandlerExtras) => T,
    notification: ActionPerformed,
    extra: ActionHandlerExtras
  ): Promise<T> {
    const instance = new this(notification, extra);
    await instance.handle();
    return instance;
  }

  async handle() {
    console.log(this.constructor.name, 'handle')
  }

  trackAction(notification: ActionPerformed): void {
    const data: BasePayload = notification.notification?.data
    const tracker = useTracking()

    tracker.trackPushNotificationAction(
      notification.actionId,
      (data.type ? `${data.type};` : 'none') + (data.tracking_id ?? notification.notification.id),
      notification.notification.data?.title
    )
  }
}


class DefaultActionPerformedHandler extends ActionPerformedHandler {
}


class InAppActionPerformedHandler extends ActionPerformedHandler {
  async handle() {
    const payload: InAppPayload = this.notification.notification.data
    console.log('InAppActionPerformedHandler', payload)

    Dialog.create({
      component: InAppDialog,
      componentProps: {
        imageUrl: payload.image_url,
        ...payload,
      }
    })
  }
}


class NewMessageActionPerformedHandler extends ActionPerformedHandler {
  async handle() {
    const payload: NewMessagePayload = this.notification.notification.data

    await this.mainStore.fetchAccountDetails()
    if (!this.mainStore.isLoggedIn) {
      await this.router.push({name: 'login', query: {required: 'yes'}})
      return
    }

    // check if to_user is current user
    if (payload.to_user === this.mainStore.accountDetail?.uuid) {
      if (payload.business_slug) {
        console.debug(`Switched to business: ${payload.business_slug}`)
      } else {
        console.debug(`Switched to user: ${this.mainStore.accountDetail?.public_name}`)
      }

      await this.mainStore.setActiveAccount(payload?.business_slug)
      await this.router.push({name: 'messenger-chat', params: {chatUuid: payload.chat_uuid}});

    } else {
      await this.router.push({name: 'messenger'})
      this.mainStore.setSiteLoading(false)
    }
  }
}


class SearchAgentPerformedActionHandler extends ActionPerformedHandler {
  async handle() {
    const payload: SearchAgentPayload = this.notification.notification.data
    await this.mainStore.fetchAccountDetails()

    await this.router.push({name: 'search-agents-detail', params: {uuid: payload.search_agent_uuid}})
  }
}


class LinkActionPerformedActionHandler extends ActionPerformedHandler {
  async handle() {
    const payload: LinkPayload = this.notification.notification.data
    const {url, link_open_external} = payload

    if (!url || url.length < 1) {
      console.error('No URL provided')
      return
    }
    if (url.toString().startsWith('https://')) {
      if (link_open_external && typeof window !== 'undefined') {
        window.open(url)
      } else {
        CapacitorBrowser.open({url: url})
      }

    } else {
      await this.router.push(url)
    }
  }
}


export const handlers = {
  new_message: NewMessageActionPerformedHandler,
  link: LinkActionPerformedActionHandler,
  in_app: InAppActionPerformedHandler,
  default: DefaultActionPerformedHandler,
  search_agent: SearchAgentPerformedActionHandler,
}
