<template>
  <q-dialog
    ref="dialogRef"
    :persistent="forceUpdate"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="onDialogHide"
  >
    <base-dialog-content :close-icon="!forceUpdate" header-label="Dein Profil">
      <div class="flex column" style="row-gap: 12px">
        <q-input
          v-model="formData.first_name"
          label="Vorname"
          outlined
          :error-message="errors.first_name ? errors.first_name.join(' ') : undefined"
          :error="!!errors.first_name"
        />
        <q-input
          v-model="formData.last_name"
          :error-message="errors.last_name ? errors.last_name.join(' ') : undefined"
          :error="!!errors.last_name"
          label="Nachname"
          outlined
        />

        <q-toggle
          v-model="formData.settings.show_full_name"
          label="Vollständigen Namen anzeigen"
        />
      </div>

      <template #footer>
        <q-card-actions align="right">
          <q-btn
            v-if="!forceUpdate"
            color="grey-6"
            label="Abbrechen"
            flat
            @click="onDialogHide"
          />
          <q-btn
            color="primary"
            label="Speichern"
            flat
            :disable="isError"
            @click="save"
          />
        </q-card-actions>
      </template>
    </base-dialog-content>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent} from 'quasar'
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';
import {onMounted, ref} from 'vue';
import {useMainStore} from 'stores/main';
import AuthApi from 'src/api/auth';


defineProps({
  forceUpdate: {
    type: Boolean,
    default: false
  }
})

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
])

const mainStore = useMainStore()
const isError = ref(false)
const errors = ref({
  first_name: undefined,
  last_name: undefined
})

const formData = ref({
  first_name: mainStore.accountDetail.first_name ?? '',
  last_name: mainStore.accountDetail.last_name ?? '',
  settings: {
    show_full_name: mainStore.accountDetail.settings?.show_full_name ?? true
  }
})


onMounted(() => {
  if (!mainStore.accountDetail || !mainStore.isLoggedIn) {
    onDialogHide()
  }
})

function save() {
  AuthApi.patchAccount(formData.value).then(() => {
    mainStore.fetchAccountDetails().finally(() => {
      onDialogOK()
    })
  }).catch((e) => {
    errors.value = e.response.data
    console.error('Error saving account data', e)
  })
}

const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()
</script>
