<template>
  <q-dialog
    ref="dialogRef"
    @hide="onDialogHide"
  >
    <base-dialog-content header-label="Update notwendig">
      <div class="text-center">
        <p>
          Du verwendest eine <b>veraltete Version</b> der App.
        </p>
        <p class="text-bold">
          Bitte aktualisiere die App, um sie weiter sicher und zuverlässig nutzen zu können.
        </p>
      </div>

      <template #footer>
        <q-card-actions align="right">
          <q-btn
            color="grey-6"
            label="Später"
            flat
            @click="onDialogHide"
          />
          <q-btn
            color="primary"
            label="Jetzt Aktualisieren"
            flat
            @click="downloadAppClick"
          />
        </q-card-actions>
      </template>
    </base-dialog-content>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent, useQuasar} from 'quasar'
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';
import {APPLE_STORE_URL, GOOGLE_PLAY_URL} from 'src/apps/core/static';
import {CapacitorAppUpdate} from 'boot/inject-capacitor';


const props = defineProps({
  withoutCapacitor: {
    type: Boolean,
    default: false
  }
})

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
])

const $q = useQuasar()


function downloadAppClick() {
  if (!props.withoutCapacitor && CapacitorAppUpdate) {
    try {
      CapacitorAppUpdate.openAppStore({country: 'DE'});
    } catch (e) {
      console.error('Error opening app store', e)
    }
  } else {
    let url
    if ($q.platform.is.ios || $q.platform.is.mac || $q.platform.is.safari) {
      // is iOS
      url = APPLE_STORE_URL
      window.open(url, '_self')
    } else {
      // is Android
      url = GOOGLE_PLAY_URL
      window.open(url, '_self')
    }
  }

  onDialogOK()
}

const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()
</script>
