import {api} from 'boot/axios';

export default {
  getAdverts: function (params: object | undefined = undefined, config: object | undefined = undefined) {
    return api.get('adverts/', {params, ...config})
  },

  getAdvertsTop: function (params) {
    return api.get('adverts/top/', {params})
  },

  getAdvertsTopWohnmobile: function (config = undefined) {
    return api.get('adverts/top-wohnmobile/', config)
  },

  getAdvertsTopCampervans: function (config = undefined) {
    return api.get('adverts/top-campervans/', config)
  },

  getAdvertsTopMiniCamper: function (config = undefined) {
    return api.get('adverts/top-mini-camper/', config)
  },

  getAdvertsRegion: function (params) {
    return api.get('adverts/', {params})
  },

  getAdvertsNewest: function (params) {
    return api.get('adverts/newest/', {params})
  },

  getAdvertsReady: function (params) {
    return api.get('adverts/ready/', {params})
  },

  getAdvertsNoBuild: function (params) {
    return api.get('adverts/no_build/', {params})
  },

  getAdvertsDealer: function (params) {
    return api.get('adverts/dealer/', {params})
  },

  getAdvertsCheap: function (params) {
    return api.get('adverts/cheap/', {params})
  },

  getAdvertsStartCards: function (params) {
    return api.get('adverts/start-cards/', {params})
  },

  getOwnAdverts: function (page = 1) {
    return api.get('adverts/own/', {params: {page}})
  },

  getAdvert: function (uuid) {
    return api.get(`adverts/${uuid}/`)
  },

  getAdvertProducts: function (uuid) {
    return api.get(`adverts/${uuid}/products/`)
  },

  getReasonChoices: function () {
    return api.get('adverts/reasons/')
  },

  deleteAdvert: function (uuid, deleted_reason = undefined) {
    return api.delete(`adverts/${uuid}/`, {params: {deleted_reason}})
  },

  getAdvertOptions: function () {
    return api.options('adverts/')
  },

  createAdvert: function (data) {
    return api.post('adverts/', data)
  },

  updateAdvert: function (data, uuid) {
    return api.put(`adverts/${uuid}/`, data)
  },

  uploadAdvertImages: function (advertUuid, data, options: any = undefined) {
    return api.post(`adverts/${advertUuid}/images/`, data, options)
  },

  deleteAdvertImage: function (advertUuid, imageUuid) {
    return api.delete(`adverts/${advertUuid}/images/${imageUuid}/`)
  },

  patchAdvertImage: function (advertUuid, imageUuid, data) {
    return api.patch(`adverts/${advertUuid}/images/${imageUuid}/`, data)
  },

  fetchAdvertImage: function (advertUuid, imageUuid) {
    return api.get(`adverts/${advertUuid}/images/${imageUuid}/`)
  },

  fetchAdvertRecommendations: function (advertUuid) {
    return api.get(`adverts/${advertUuid}/recommendation/`)
  },

  fetchAdvertImageBase64Data: function (advertUuid, imageUuid) {
    return api.get(`adverts/${advertUuid}/images/${imageUuid}/data/`)
  },

  fetchAdvertImages: function (advertUuid) {
    return api.get(`adverts/${advertUuid}/images/`)
  },

  patchAdvert: function (uuid, data) {
    return api.patch(`adverts/${uuid}/`, data)
  },

  setAdvertStatus(uuid, status: boolean) {
    return api.patch(`adverts/${uuid}/set_status/`, {active: status})
  },

  setExtendAdvert(uuid) {
    return api.post(`adverts/${uuid}/extend/`)
  },

  setAdvertImagePostion(advertUuid, imageUuid, position) {
    return api.post(`adverts/${advertUuid}/images/${imageUuid}/set_postion/`, {position})
  },

  setAdvertImagePosImageUp(advertUuid, imageUuid) {
    return api.post(`adverts/${advertUuid}/images/${imageUuid}/ordering_pos_up/`)
  },

  setAdvertImagePosImageDown(advertUuid, imageUuid) {
    return api.post(`adverts/${advertUuid}/images/${imageUuid}/ordering_pos_down/`)
  },

  setAdvertSearchAgent(filterData, name=undefined) {
    return api.post('adverts/search-agents/', {filter_data: filterData, name})
  },

  getAdvertSearchAgents() {
    return api.get('adverts/search-agents/')
  },

  getAdvertSearchAgent(uuid) {
    return api.get(`adverts/search-agents/${uuid}`)
  },

  getAdvertSearchAgentAdvertList(uuid) {
    return api.get(`adverts/search-agents/${uuid}/adverts_list/`)
  },

  getAdvertSearchAgentAdvertListNew(uuid) {
    return api.get(`adverts/search-agents/${uuid}/adverts_list_new/`)
  },

  patchAdvertSearchAgent(uuid, data) {
    return api.patch(`adverts/search-agents/${uuid}/`, data)
  },

  deleteAdvertSearchAgent(uuid) {
    return api.delete(`adverts/search-agents/${uuid}/`)
  },

  getAdvertImportJob(uuid) {
    return api.get(`adverts/import/${uuid}/`)
  },
  getAdvertImportJobs() {
    return api.get('adverts/import/')
  },
  createAdvertImportJob(data) {
    return api.post('adverts/import/', data)
  },

  // Favorites
  getUserFavorites: function () {
    return api.get('adverts/favorites/',)
  },

  getUserLastViewed: function (config = undefined) {
    return api.get('adverts/last-viewed/', config)
  },

  getUserFavorite: function (favoriteUuid) {
    return api.get(`adverts/favorites/${favoriteUuid}/`,)
  },
  toggleUserFavorite: function (advertUuid) {
    return api.post(`adverts/${advertUuid}/toggle-favorite/`)
  },
  removeFavorite: function (favoriteUuid) {
    return api.delete(`adverts/favorites/${favoriteUuid}/`,)
  },

  // filter mapping
  filterMapping: function () {
    return api.get('adverts/advert-filter-fields/',)
  },

  getMapList: function (params: object | undefined = undefined, cancelToken: any = undefined) {
    return api.get('adverts/map/', {params: params, cancelToken: cancelToken})
  },

  getWohnmobilBrands: function () {
    return api.get('adverts/wohnmobile-brands/')
  },

  getCampervanBrands: function () {
    return api.get('adverts/campervan-brands/')
  },

  getMiniCamperBrands: function () {
    return api.get('adverts/mini-camper-brands/')
  }
}
