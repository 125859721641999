import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "q-py-xs text-small text-center text-white text-bold bg-negative"
}

import {onBeforeUnmount, onMounted, provide, ref, useSSRContext, watchEffect} from 'vue'
import {useMeta} from 'quasar'
import LoadingScreen from 'src/components/LoadingScreen.vue';
import {useRoute, useRouter} from 'vue-router';
import {
  appUrlOpenListener,
  backBtnListener,
  registerDevice,
} from 'src/etc/helper';
import {setUpNotifications} from 'src/etc/notifications';
import {
  CapacitorAppUpdate,
  CapacitorDevice,
  CapacitorPushNotifications,
  CapacitorTrackingTransparency
} from 'boot/inject-capacitor';
import {Network} from '@capacitor/network';
import {cookieConsent as cookieConsentObj} from 'src/boot/cookies';
import CookieConsent from 'components/qutils/cookieConsent/CookieConsent.vue';
import InitLoadingScreen from 'src/apps/core/components/InitLoadingScreen.vue';
import {usePersistentLocalStore} from 'src/apps/core/persistent-store';
import {SEARCH_TYPES} from 'src/apps/search/vars';
import AppForceUpdateDialog from 'src/apps/core/dialogs/AppForceUpdateDialog.vue';
import {createUpdateDeviceAppVersionLog} from 'src/apps/core/utils';
import Core from 'src/api/core';
import ChangeNameDialog from 'src/apps/core/dialogs/ChangeNameDialog.vue';
// import ErrorPage from 'pages/ErrorPage.vue';



import {useMainStore} from 'stores/main';
import {useSubscriptionStore} from 'src/apps/subscription/store';
import {Cookies, Platform, useQuasar} from 'quasar';
import {CapacitorPreferences} from 'boot/inject-capacitor';
import AppStartScreen from './apps/core/pages/AppStartScreen.vue';
import {useSearchStore} from 'src/apps/search/store'
import {getAppInfo} from 'src/apps/core/utils';

const __default__ = {
  async preFetch({store, ssrContext, redirect, currentRoute}: any) {
    const mainStore = useMainStore(store)
    const subStore = useSubscriptionStore(store)
    const searchStore = useSearchStore(store)
    searchStore.shuffleVehicleSearchAds()
    searchStore.shuffleMarketSearchAds()

    try {
      await getAppInfo(store)
    } catch (e) {
      console.warn(e)
    }

    try {
      mainStore.fetchVehicleExtras().catch(console.error)
      mainStore.fetchFilterMapping().catch(console.error)
      await mainStore.fetchSiteConfig()
    } catch (e) {
      console.error(e)
    }

    const cookies = process.env.SERVER
      ? Cookies.parseSSR(ssrContext)
      : Cookies // otherwise we're on client

    const platform = process.env.SERVER
      ? Platform.parseSSR(ssrContext)
      : Platform // otherwise we're on client

    let capToken
    if (platform.is.nativeMobile) {
      const {value} = await CapacitorPreferences.get({key: 'token'})
      capToken = value
    }

    if (cookies.has('token') || capToken) {
      try {
        await mainStore.fetchAccountDetails()
        await subStore.fetchAccountSubscription()
        await subStore.fetchAccountQuota()
      } catch (err) {
        console.error(err)
      }
    }

    mainStore.siteInitLoading = false

    // redirect straight to search page if Index.vue page and user is logged
    if (
      mainStore.isLoggedIn
      && currentRoute.name === 'home'
      && !cookies.get('navigatedToStart')
      && mainStore.isFirstLoad
    ) {
      return redirect({name: 'search'})
    }

  }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'App',
  setup(__props) {

const cookieText = `
Wir verwenden Cookies, um sicherzustellen, dass unsere Website reibungslos funktioniert und um unsere Services für dich zu verbessern. Einige dieser Cookies sind für die grundlegende Funktionalität unserer Website notwendig, während andere uns helfen, deine Nutzererfahrung zu personalisieren und zu optimieren.

Wir verwenden auch Cookies von Drittanbietern, um anonymisierte Statistiken über die Nutzung unserer Website zu sammeln und um unsere Marketingbemühungen zu unterstützen.`
// Wenn Sie nicht möchten, dass wir Cookies auf Ihrem Gerät speichern, können Sie auf "Abbrechen" klicken und unsere Website wird dennoch für Sie zugänglich sein. Bitte beachten Sie jedoch, dass bestimmte Funktionen möglicherweise nicht verfügbar sind, wenn Sie Cookies ablehnen.

const route = useRoute()
const router = useRouter()
const drawer = ref(false)
const mainStore = useMainStore()
const searchStore = useSearchStore()
const persistStore = usePersistentLocalStore()
const $q = useQuasar()


provide('drawer', drawer)


// MESSAGE POLLING
let msgPolling, timeout


const setUnreadTitle = function () {
  const val = mainStore.messagesUnread
  const regexp = /\(\d*\)/gi;
  if (val > 0 && document) {
    if (!document.title.match(regexp)) {
      document.title = `(${val}) ${document.title}`
    } else {
      document.title = document.title.replace(regexp, `(${val})`)
    }
  } else {
    document.title = document.title.replace(regexp, '')
  }
}


async function setLanguage() {
  const lang = persistStore.language
  if (!lang) {
    persistStore.language = 'de-DE'
  }
  console.debug(`setLanguage: ${persistStore.language}`)
}


function setMessagePolling() {
  try {
    timeout = Number.parseInt(process.env.UNREAD_MESSAGES_POLLING || '')
  } catch (e) {
    console.error(e)
  }
  if (timeout) {
    msgPolling = setInterval(() => {
      if (!mainStore.isLoggedIn) return
      mainStore.fetchUnreadMessagesCount()
      setUnreadTitle()
    }, timeout)
  }
}


async function setNetworkStatusListener() {
  try {
    mainStore.networkStatus = await Network.getStatus()
    await Network.addListener('networkStatusChange', status => {
      mainStore.networkStatus = status
    })
  } catch (e) {
    console.error(e)
  }
}


async function appAcceptCookies() {
  if (!$q.platform.is.nativeMobile) return
  let status = 'authorized'

  if (status !== 'authorized') {
    cookieConsentObj.cookieScripts.forEach(item => {
      console.debug(`Cookie - processing ${item.name}`)
      if (item.required) {
        item.onAccept()
        console.debug(`${item.name} on`)
      } else {
        item.onDecline()
        console.debug(`${item.name} off`)
      }
    })
  } else {
    cookieConsentObj.cookieScripts.forEach(item => {
      console.debug(`Cookie - processing ${item.name}`)
      item.onAccept()
    })
  }

  cookieConsentObj.isProcessed = true
}


async function checkAppPermission() {
  if (!$q.platform.is.nativeMobile) return
  const shouldRequest: Array<'tracking' | 'notification'> = []

  // check tracking if ios
  if ($q.platform.is.ios) {
    let trackingStatus
    try {
      const {status} = await CapacitorTrackingTransparency.getTrackingStatus()
      trackingStatus = status
    } catch (error) {
      console.error('Tracking Error:', error)
    }
    console.debug('trackingStatus', trackingStatus)
    const {value} = await CapacitorPreferences.get({key: 'permission_tracking_prompted'})
    if (trackingStatus === 'unrequested' && value !== 'true') {
      shouldRequest.push('tracking')
    }
  }

  // check notification
  let permStatus = await CapacitorPushNotifications.checkPermissions();
  if (permStatus.receive === 'granted') {
    await CapacitorPushNotifications.register()
    return
  }

  const {value} = await CapacitorPreferences.get({key: 'permission_notification_prompted'})
  const parsedDate = new Date(value);
  if (!value || (parsedDate < new Date())) {
    shouldRequest.push('notification')
  }

  console.debug('shouldRequest', shouldRequest)

  if (shouldRequest.length > 0) {
    $q.dialog({
      component: AppStartScreen,
      componentProps: {
        screens: shouldRequest
      }
    })
  }
}


function setMatomoUser() {
  if (typeof window !== 'undefined') {
    const _paq: any = window._paq || []

    try {
      if ($q.platform.is.nativeMobile) {
        CapacitorDevice.getId().then(({identifier}) => {
          _paq.push(['setUserId', identifier]);
        })
      } else {
        _paq.push(['setUserId', mainStore.accountDetail?.uuid]);
      }
    } catch (err) {
      console.error(err)
    }
  }
}


async function setSearchType() {
  const searchTypeQuery = route.query.t?.toString()
  const searchType = persistStore.getActiveSearchType
  if (
    (!searchTypeQuery || !SEARCH_TYPES.includes(searchTypeQuery))
    && (searchType && SEARCH_TYPES.includes(searchType))
  ) {
    searchStore.updateSearchType(searchType)
  }
}


async function checkAppForceUpdate() {
  if (!$q.platform.is.capacitor) return

  if (!mainStore.appInfo?.version) {
    await getAppInfo()
  }

  const {data} = await Core.checkIfForceUpdate(mainStore.appInfo.version)
  const isForceUpdate = data?.is_force_app_update
  console.debug('checkAppForceUpdate', 'isForceUpdate:', isForceUpdate, 'Version:', mainStore.appInfo.version)

  if (!isForceUpdate) return

  try {
    if (CapacitorAppUpdate) {
      const result = await CapacitorAppUpdate.getAppUpdateInfo({country: 'DE'});
      if (result.updateAvailability !== 2) {
        console.debug('No update available', result);
        return
      }
      if (result.flexibleUpdateAllowed) {
        await CapacitorAppUpdate.startFlexibleUpdate();
      } else {
        $q.dialog({
          component: AppForceUpdateDialog,
          persistent: true,
        })
      }
    }
  } catch (e) {
    console.debug('checkAppForceUpdate fallback', e)
    $q.dialog({
      component: AppForceUpdateDialog,
      persistent: true,
      componentProps: {
        withoutCapacitor: true
      }
    })
  }
}


function checkNameIsSet() {
  if (!mainStore.isLoggedIn) {
    return
  }
  if (!mainStore.accountDetail?.first_name || !mainStore.accountDetail?.last_name) {
    $q.dialog({
      component: ChangeNameDialog,
    })
  }
}

onMounted(async () => {
  console.debug('App mounted')
  await persistStore.restored
  await setLanguage()
  await setSearchType()

  // set up matomo
  const _paq: any = window._paq || []
  _paq.push(['trackVisibleContentImpressions',]);
  setMatomoUser()

  try {
    await checkAppPermission()
  } catch (error) {
    console.error('checkAppPermission Error:', error)
  }

  const ssrContext = process.env.SERVER ? useSSRContext() : null
  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies // otherwise we're on client

  if (cookies.has('token')) {
    mainStore.fetchAccountDetails().then(() => {
      mainStore.fetchUnreadMessagesCount()
      setUnreadTitle()

      if (!$q.platform.is.nativeMobile) {
        // Firebase Messaging
        try {
          registerDevice()
          setUpNotifications(router, route)
        } catch (err) {
          console.error(err)
        }
      }

    })
  }

  setMessagePolling()

  appUrlOpenListener($q, router)

  backBtnListener(route, router, searchStore)
  await setNetworkStatusListener()

  try {
    checkNameIsSet()
  } catch (e) {
    console.error(e)
  }

  if ($q.platform.is.nativeMobile) {
    try {
      await createUpdateDeviceAppVersionLog()
    } catch (e) {
      console.error(e)
    }

    try {
      await checkAppForceUpdate()
    } catch (e) {
      console.error('checkAppForceUpdate', e)
    }
  }
})


onBeforeUnmount(() => {
  if (msgPolling) clearInterval(msgPolling)
  if (Network) {
    Network.removeAllListeners()
  }
})


watchEffect(() => {
  appAcceptCookies()
})


useMeta(() => {
  const mainStore = useMainStore()

  return {
    // sets document title
    title: 'Camper Trader',

    // meta tags
    meta: {
      description: {
        name: 'description',
        content: 'Nutze unsere erweiterte Suche und entdecken unsere große Auswahl an ' +
          'Campern, Wohnmobilen und Kastenwagen. Kaufe und verkaufe deinen Gebrauchtwagen!'
      },
      keywords: {
        name: 'keywords',
        content: 'Camper Trader, Wohnmobile kaufen, Wohnwagen kaufen, Camper Vans kaufen, Gebrauchte Wohnmobile, Gebrauchte Wohnwagen, Gebrauchte Campervans, Privat verkaufen, Privat kaufen, Gebrauchte Camper kaufen, Wohnmobilmarkt, Wohnwagenmarkt, Wohnmobile von privat, Wohnwagen von privat, Camper von privat, Campingfahrzeuge, Camper inserieren, Wohnmobile inserieren, Wohnwagen inserieren, Camper Trader Deutschland'
      },
      // equiv: { 'http-equiv': 'Content-Type', content: 'text/html; charset=UTF-8' },
      // note: for Open Graph type metadata you will need to use SSR, to ensure page is rendered by the server
      ogTitle: {
        property: 'og:title',
        // optional; similar to titleTemplate, but allows templating with other meta properties
        template() {
          if (!mainStore.siteConfig['site_subheader']) {
            return 'Camper Trader'
          }
          return `Camper Trader - ${mainStore.siteConfig['site_subheader']}`
        }
      },
      ogType: {
        property: 'og:type',
        template() {
          return 'website'
        }
      },
      fbAppId: {
        property: 'fb:app_id',
        template() {
          return '3404774996425496'
        }
      },
      ogUrl: {
        property: 'og:url',
        template() {
          return `${process.env.BASE_URL}${route.fullPath}`
        }
      },
      ogImage: {
        property: 'og:image',
        template() {
          if (mainStore.siteConfig.hasOwnProperty('site_og_default_image')) {
            return mainStore.siteConfig['site_og_default_image']?.path
          }
          return ''
        }
      },
      ogImageAlt: {
        property: 'og:image:alt',
        template() {
          return 'Camper Trader Logo'
        }
      },
      ogDescription: {
        property: 'og:description',
        template() {
          if (mainStore.siteConfig.hasOwnProperty('site_og_default_description')) {
            return mainStore.siteConfig['site_og_default_description']
          }
          return ''
        }
      },
      ogLocale: {
        property: 'og:locale',
        template() {
          return 'de_DE'
        },
      },
      ogSiteName: {
        property: 'og:site_name',
        template() {
          return 'Camper Trader'
        },
      },
    },

    script: {
      website: {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          'name': 'Camper Trader',
          'alternateName': 'Nutze unsere erweiterte Suche und entdecken unsere große Auswahl an ' +
            'Campern, Wohnmobilen und Kastenwagen. Kaufe und verkaufe deinen Gebrauchtwagen!',
          'url': 'https://campertrader.de',
        }),
      },

      organization: {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'Camper Trader',
          legalName: 'Lysiak & Tröndle GbR',
          url: 'https://campertrader.de',
          sameAs: [
            'https://www.instagram.com/campertrader.de/',
          ]
        })
      }
    },

    noscript: {
      default: 'Please enable Javascript to use this website.'
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(mainStore).networkStatus?.connected === false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Du bist offline "))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (_unref(mainStore).siteInitLoading && _unref($q).platform.is.nativeMobile)
      ? (_openBlock(), _createBlock(InitLoadingScreen, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(LoadingScreen),
    (!_unref($q).platform.is.nativeMobile)
      ? (_openBlock(), _createBlock(_component_q_no_ssr, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(CookieConsent, {
              modelValue: _unref(mainStore).showCookieBanner,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(mainStore).showCookieBanner) = $event)),
              "btn-accept-label": 'Alle akzeptieren',
              "btn-cancel-label": 'Abbrechen',
              "btn-deny-label": 'Nur notwendige',
              "btn-save-label": 'Speichern',
              "btn-setting-label": 'Benutzerdefiniert',
              text: cookieText,
              title: "Wir nutzen Cookies - Aber warum?",
              disabled: !!_ctx.$route.meta.disableCookieConsent
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})